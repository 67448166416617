import React, { useEffect } from 'react';
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { SiAcclaim } from "react-icons/si"
import { RiLogoutCircleRLine, RiLoginCircleLine } from "react-icons/ri"
import { AiOutlineAlignCenter } from "react-icons/ai"
import { connect } from "react-redux";
import { getCategories, beforeVideo } from "../../MemberDashboard/Videos/video.actions";
import { ENV } from "../../../../config/config";
import { beforeCounter } from '../../Notifications/notifications.actions';
import { becomeMemberSection, dashboardCategory } from '../../ApplicationTour/TourCompIdentifiers';
import { Images } from "../../../../assets/assets"
import { useTranslation } from 'react-i18next';
import { clearStorage } from '../../../../utils/Shared Functions/shared';
import './MainSidebar.css'

const { defaultPlaceholderImage } = ENV

const MainSidebar = ({ sidebar, setSidebar, getCategories, beforeVideo, videos }) => {
    const { t, i18n } = useTranslation()
    const navigate = useNavigate()
    const [show, setShow] = useState(false)
    const [categories, setCategories] = useState();
    const [showMenu, setShowMenu] = useState(false)
    const userId = ENV.getUserKeys('_id')?._id
    const currentLanguage = localStorage.getItem('selectedLang')

    const menuShow = () => {
        setShowMenu(!showMenu);
    }

    const logoutHandler = () => {
        clearStorage()
        beforeCounter()
        navigate('/login')
    }
    useEffect(() => {
        if (i18n.language)
            getCategories(`lng=${currentLanguage}`);
    }, [i18n.language])

    useEffect(() => {
        if (videos.videoCategoriesAuth) {
            setCategories(videos.videoCategories.categories);
            beforeVideo();
        }
    }, [videos.videoCategoriesAuth]);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 992) {
                setSidebar(true);
            }
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Call handleResize initially to set the initial state
        handleResize();

        // Remove event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            <div className={`mainSidebar ${showMenu ? "show" : ""}`}>
                <div className={sidebar ? 'sidebarLogo rotate' : 'sidebarLogo logo-change no-rotate'}
                    onClick={() => setSidebar(!sidebar)}><SiAcclaim></SiAcclaim></div>
                {
                    sidebar ?
                        <div className="sidebarStyle sidebarFlex" onMouseOver={() => setShow(!show)}>
                            <div className="mobile-button" onClick={menuShow}>
                                <AiOutlineAlignCenter />
                            </div>
                            <div className='sidebarLink'>
                                <h3>{t('mainSidebar.browse')}</h3>
                                <ul>
                                    <li >
                                        <Link to={'/'} className={`${window.location.pathname?.toLowerCase() === '/' ? 'active' : ''}`} >
                                            <span className='d-flex align-items-center'>
                                                <div className='sidebar-img me-2'>
                                                    <img className='img-fluid' src={Images.iconHomeVideos} alt='all' />
                                                </div>
                                                <div>{t('mainSidebar.home')}</div>
                                            </span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/my-favourites'} className={`${window.location.pathname?.toLowerCase() === '/my-favourites' ? 'active' : ''}`}>
                                            <span className='d-flex align-items-center'>
                                                <div className='sidebar-img me-2'>
                                                    <img className='img-fluid' src={Images.iconFavoriteVideos} alt='all' />
                                                </div>
                                                <div>{t('mainSidebar.favourites')}</div>
                                            </span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/watch-history'} className={`${window.location.pathname?.toLowerCase() === '/watch-history' ? 'active' : ''}`}>
                                            <span className='d-flex align-items-center'>
                                                <div className='sidebar-img me-2'>
                                                    <img className='img-fluid' src={Images.iconHistroyVideos} alt='all' />
                                                </div>
                                                <div>{t('mainSidebar.history')}</div>
                                            </span>
                                        </Link>
                                    </li>
                                </ul>
                                <h3 id={dashboardCategory}>{t('mainSidebar.exploreCategories')}</h3>
                                <ul>
                                    <li>
                                        <span className={ window.location.pathname === '/' ? 'active sidebar-span' : ''}>
                                            <span className='d-flex align-items-center'>
                                                <div className='sidebar-img me-2'>
                                                    <img className='img-fluid' src={Images.iconAllVideos} alt={t('mainSidebar.all')} />
                                                </div>
                                                <div>{t('mainSidebar.all')}</div>
                                            </span>
                                        </span>
                                    </li>
                                    {
                                        categories && categories.map((val, key) => (
                                            <li key={key}>
                                                <Link to={'/explore/' + val.url} className={window.location.pathname === `/explore/${val.url}` ? 'active' : ''}>
                                                    <span className='d-flex align-items-center'>
                                                        <div className='sidebar-img me-2'>
                                                            <img className='img-fluid' src={val.imageLocal} alt={val?.name} />
                                                        </div>
                                                        <div>{val.name}</div>
                                                    </span>
                                                </Link>
                                            </li>
                                        ))
                                    }
                                </ul>
                                <h3 id={becomeMemberSection} className='text-center px-0 mb-0'>{t('mainSidebar.becomePublisher')}</h3>
                                <div className='text-center memberImg py-4'>
                                    <Link to='/card-details'><img className='img-fluid' src={Images.memberImg} alt='Become-a-memeber' /></Link>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center align-items-centger">
                                {
                                    userId ?
                                        <button type="button" className="theme-btn orange-btn dbLogoutBtn mb-2" onClick={logoutHandler}>{t('memberDashboardSidebar.signOut')}</button>
                                        :
                                        <button type="button" className="theme-btn orange-btn dbLogoutBtn mb-2" onClick={() => navigate('/login')}>{t('mainSidebar.signIn')}</button>
                                }
                            </div>
                        </div>
                        :
                        <div className='sidebarStyle sidebarFlex w2 mini-sidebar'>
                            <div >
                                <ul>
                                    <li>
                                        <span className={window.location.pathname === '/' ? 'active' : ''}>
                                            <span className='d-flex align-items-center'>
                                                <div className='sidebar-img me-2'>
                                                    <img className='img-fluid' src={Images.allMedia} alt='All' />
                                                </div><span className='menu-name'>{t('mainSidebar.all')}</span>
                                            </span>
                                        </span>
                                    </li>
                                    {
                                        categories && categories.map((val, key) => (
                                            <li key={key}>
                                                <Link to={'/explore/' + val.url} className={window.location.pathname === `/explore/${val.url}` ? 'active' : ''}>
                                                    <span className='d-flex align-items-center'>
                                                        <div className='sidebar-img me-2'>
                                                            <img className='img-fluid' src={ENV.assetUrl + val.imageLocal} alt={val?.name} />
                                                        </div>
                                                        <span className='menu-name'>{val.name}</span>
                                                    </span>
                                                </Link>
                                            </li>
                                        ))
                                    }
                                </ul>
                                <div className='text-center memberImg mobileMemberImg'>
                                    <Link to='/packages' className='d-block'><img className='img-fluid' src={Images.memberImg} alt='packages' /></Link>
                                </div>
                            </div>
                            <div>
                                {
                                    userId ?
                                        <button type="button" className="theme-btn logout text-uppercase p-2 mx-auto dbLogoutBtnMob" onClick={logoutHandler}><RiLogoutCircleRLine /></button>
                                        :
                                        <button type="button" className="theme-btn orange-btn dbLogoutBtn mt-2" onClick={() => navigate('/login')}><RiLoginCircleLine /></button>
                                }
                            </div>
                        </div>
                }
            </div>
        </>
    )
}


const mapStateToProps = (state) => ({
    videos: state.videos,
});

export default connect(mapStateToProps, {
    getCategories,
    beforeVideo,
    beforeCounter
})(MainSidebar);